.location_actions {
	width: 100%;
	a, span {
		display: inline-block;
		width: 32%;

	}
}

span.online {
	color: #43d967;
}

span.offline {
	color: #243948;
	i::before {
		content: "\f05e";
	}
}

span.error {
	color: #ec2121;
	i::before {
		content: "\f071";
	}
}

.ReactModalPortal {
	div {
		font-weight: 600;
	}

	h2 {
		min-width: 300px;
	}

	.sensor_wrapper {
		background: #f8f8f8;
    	margin: 15px 0;
    	padding: 10px;
	}

	span.status_value {
		float: right;
	    font-weight: 400 !important;
	    display: inline-block;
	    text-align: left;
	    width: 65%;

	    span {
	    	text-align: right;
		    width: 25%;
		    display: inline-block;
	    }
	}


}