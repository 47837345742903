/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.1.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/common/variables";
// Layout
@import "app/layout/layout";
@import "app/layout/layout-extra";
@import "app/layout/layout-animation";
@import "app/layout/top-navbar";
@import "app/layout/sidebar";
@import "app/layout/offsidebar";
@import "app/layout/user-block";
@import "app/layout/settings";
// Common
@import "app/common/typo";
@import "app/common/bootstrap-reset";
@import "app/common/bootstrap-custom";
@import "app/common/button-extra";
@import "app/common/placeholder";
@import "app/common/cards";
@import "app/common/circles";
@import "app/common/dropdown-extra";
@import "app/common/half-float";
@import "app/common/animate";
@import "app/common/slim-scroll";
@import "app/common/inputs";
@import "app/common/utils";
@import "app/common/print";
// Elements
@import "app/elements/nestable";
@import "app/elements/notify";
@import "app/elements/spinner";
// Charts
@import "app/charts/radial-bar";
@import "app/charts/chart-flot";
@import "app/charts/chart-easypie";
// Form elements
@import "app/forms/form-validation";
@import "app/forms/form-tags";
@import "app/forms/form-datepicker";
@import "app/forms/form-wizard";
@import "app/forms/form-imgcrop";
@import "app/forms/dropzone";
@import "app/forms/plugins";
// Tables
@import "app/tables/datatable";
@import "app/tables/table-extras";
@import "app/tables/bootgrid";
// Maps
@import "app/maps/gmap";
@import "app/maps/vector-map";
// Extras
@import "app/extras/timeline";
@import "app/extras/todo";
@import "app/extras/calendar";
@import "app/extras/mailbox";
@import "app/extras/plans";
// Customs
@import "custom/locations";


.overlay {
	&--full {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999999;
		background: rgba(255, 255, 255, .5);
	}
}

.popup {
	&__backdrop {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999999;
		background: rgba(0, 0, 0, .5);
	}

	&__wrapper {
		width: 480px;
		height: 200px;
		margin: 0 auto;
		margin-top: calc(50vh - 100px);
	}

	&__content {
		background: white;
		width: 100%;
		float: left;
		padding: 25px;
	}
}


.navbar-brand {
	background: white;

	&:after {
		    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px);
	}

	@media(max-width: 768px) {
		width: 150px;
	}

	@media(max-width: 468px) {
		width: 115px;
	}
}

@media(max-width: 768px) {
	.hidden-xs {
		display : none !important;
	}
	/*.rt-tr-group {
		display: block !important;
		.rt-tr {
			display: block !important;
			width: 100%;
		}
		.rt-td {
			display: block;
			width: 100% !important;
		}
	}*/

	.large-button-mobile {
		padding: 35px 0;
	}


	.topnavbar .navbar-nav > .nav-item > .nav-link .fas.fa-bars {
		font-size: 26px;
	}

	.topnavbar .navbar-header {
		padding-left: 3rem;
	}

	.topnavbar .navbar-header .brand-logo {
		padding: 4px 5px;
	}

	.sidebar-nav #user-block {
		display: block !important;
	}

}

.spinner {
	width: 100px;
	height: 100px;
	border: 2px dotted black;
	border-radius: 100px;
	margin: 0 auto;
	margin-top: calc(50vh - (100px + 62px));
	animation-name: rotate; 
    animation-duration: 10s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}


.sidebar-nav {
	font-size: 16px;
}

.nav-link {
	em {
		color: white;
		font-size: 18px;
	}
}
.sidebar > .sidebar-nav > li > div > span,
.sidebar > .sidebar-nav > li > a > span {
	font-weight: bold !important;
}

.user-block-info a {
	color: black;
	font-weight: normal;
	text-decoration: underline;
}